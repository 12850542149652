<template>
  <div class="home">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Продукты ({{ filters.total }})
      <div class="flex items-center">
        <router-link :to="{ name: 'Product', params: { id: 'new' } }">
          <el-button round plain size="small" type="primary">
            Добавить товар
          </el-button>
        </router-link>

        <template v-if="false">
          <el-upload
            v-if="false"
            class="mr-2"
            :action="uploadUri"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :data="{ type: 'file' }"
          >
            <el-button round plain size="small" type="primary"
              >Импорт формат каспи</el-button
            >
          </el-upload>
          <el-button
            v-loading="loading"
            :disabled="loading"
            @click="dialogVisible = true"
            size="small"
            type="success"
            plain
            round
          >
            Kaspi лог
          </el-button>
          <el-upload
            class="ml-2"
            :action="uploadUri"
            :headers="headers"
            :show-file-list="false"
            :on-success="loadProducts"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :data="{ type: 'file' }"
          >
            <el-button round plain size="small" type="warning"
              >Импорт формат formp</el-button
            >
          </el-upload>
          <el-upload
            class="ml-2"
            :action="uploadUri"
            :headers="headers"
            :show-file-list="false"
            :on-success="importHalykProducts"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :data="{ type: 'file' }"
          >
            <el-button round plain size="small" type="warning"
              >Эксель халык</el-button
            >
          </el-upload>
          <el-dropdown @command="exportProducts" trigger="click">
            <el-button
              style="margin-left: 10px"
              size="small"
              plain
              round
              type="primary"
            >
              Экспорт <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="kaspi"
                >Экспорт товаров каспи</el-dropdown-item
              >
              <el-dropdown-item command="halyk"
                >Экспорт товаров халык</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </h1>
    <div class="p-5 bg-gray-100 flex items-center mb-3">
      <div class="mr-10">
        В продаже
        <el-checkbox
          :true-label="1"
          :false-label="0"
          @change="handleFiltersChange"
          v-model="filters.type"
        ></el-checkbox>
      </div>
      <div v-if="showDamping && filters.type === 1" class="mr-10">
        В демпинге
        <el-checkbox
          :true-label="1"
          :false-label="0"
          @change="handleFiltersChange"
          v-model="filters.damping"
        ></el-checkbox>
      </div>
      <div v-if="showDamping && filters.type === 1" class="mr-10">
        Интервал
        <el-select
          v-model="filters.interval_type"
          @change="handleFiltersChange"
        >
          <el-option label="All" :value="null"></el-option>
          <el-option label="Low" value="low"></el-option>
          <el-option label="Middle" value="mid"></el-option>
          <el-option label="High" value="high"></el-option>
        </el-select>
      </div>
      <div v-if="showDamping && filters.type === 1" class="mr-10">
        В предзаказе
        <el-checkbox
          :true-label="1"
          :false-label="0"
          @change="handleFiltersChange"
          v-model="filters.pre_order"
        ></el-checkbox>
      </div>
      <el-input
        v-model="filters.query"
        placeholder="Поиск"
        @keypress.enter.native="handleFiltersChange"
        class="mr-2"
      >
        <i
          v-if="filters.query"
          slot="suffix"
          style="cursor: pointer"
          class="el-input__icon el-icon-close"
          @click="
            filters.query = null;
            handleFiltersChange();
          "
        ></i>
      </el-input>
      <el-button :disabled="loading" @click="handleFiltersChange"
        >Поиск</el-button
      >
    </div>

    <ProductsGroupEvents
      v-if="selected_products_id.length"
      :selected_products_id="selected_products_id"
    ></ProductsGroupEvents>

    <el-table
      border
      v-loading="loading"
      :data="products"
      stripe
      style="width: 100%"
      size="medium"
    >
      <el-table-column width="40" align="center">
        <template slot-scope="scope">
          <el-checkbox
            @change="() => selectProduct(scope.row.id)"
            :value="selected_products_id.includes(scope.row.id)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        align="center"-->
      <!--        type="index"-->
      <!--        width="50"-->
      <!--        :index="indexMethod"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column prop="title" label="Название">
        <template slot-scope="scope">
          {{ scope.row.brand }} <br />
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" width="120"></el-table-column>
      <el-table-column
        prop="kaspi_sku"
        label="SKU(kaspi)"
        width="120"
      ></el-table-column>
      <el-table-column prop="brand" label="Цена">
        <template slot-scope="scope">
          <ul v-if="scope.row.prices">
            <li v-for="price in scope.row.prices" :key="price.id">
              {{ cities[price.city_id] || price.city_id }} -
              {{ numberFormat(price.price) }} тг
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column v-if="showDamping" prop="brand" label="Дэмпинг">
        <template slot-scope="scope">
          <ul v-if="scope.row.offers">
            <li v-for="offer in scope.row.offers" :key="offer.id">
              {{ cities[offer.city_id] || offer.city_id }} |
              {{ dateTimeFormat(offer.last_updated) }} |
              <span
                :class="
                  getBranchIntervalTypeOfProduct(
                    offer.city_id,
                    scope.row.prices
                  )
                "
              >
                {{
                  getBranchIntervalTypeOfProduct(
                    offer.city_id,
                    scope.row.prices
                  )
                }}
              </span>
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="brand" label="Действия">
        <template slot-scope="scope">
          <el-button
            @click.stop="editProduct(scope.row)"
            size="small"
            plain
            type="primary"
            icon="el-icon-edit"
          ></el-button>
          <!--          <el-button-->
          <!--            @click.stop="confirmDelete(scope.row.id)"-->
          <!--            size="small"-->
          <!--            plain-->
          <!--            type="danger"-->
          <!--            icon="el-icon-delete"-->
          <!--          ></el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>

    <el-dialog title="Kaspi логи" :visible.sync="dialogVisible" width="50%">
      <div>
        <el-input v-model="logs" type="textarea"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Отмена</el-button>
        <el-button
          :disabled="!logs.trim() || loading"
          type="primary"
          @click="parseKaspiLog"
        >
          Отправить
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProducts,
  deleteProduct,
  importProducts,
  parseKaspiLog,
  importHalykProducts,
  importFormpProducts,
} from "@/api/product";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();
const DOMAIN = process.env["VUE_APP_DOMAIN"];
import { numberFormat, dateTimeFormat } from "@/helpers/formatter";
import ProductsGroupEvents from "@/components/ProductsGroupEvents";

export default {
  name: "Home",
  components: {
    ProductsGroupEvents,
  },
  data: () => ({
    products: [],
    loading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
    filters: {
      type: 0,
      damping: 0,
      pre_order: 0,
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 20,
      query: null,
      interval_type: null,
    },
    dialogVisible: false,
    logs: "",
    cities: {
      710000000: "Астана",
      750000000: "Алматы",
    },
    selected_products_id: [],
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
    page() {
      return this.$route.query.page;
    },
    type() {
      return this.$route.query.type;
    },
    damping() {
      return this.$route.query.damping;
    },
    interval_type() {
      return this.$route.query.interval_type;
    },
    pre_order() {
      return this.$route.query.pre_order;
    },
    query() {
      return this.$route.query.query;
    },
    showDamping() {
      return location.hostname !== "app.formp.kz";
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = parseInt(this.page, 10);
    }
    if (this.type) {
      this.filters.type = parseInt(this.type, 10);
    }
    if (this.damping) {
      this.filters.damping = parseInt(this.damping, 10);
    }
    if (this.interval_type) {
      this.filters.interval_type = this.interval_type;
    }
    if (this.pre_order) {
      this.filters.pre_order = parseInt(this.pre_order, 10);
    }
    if (this.query) {
      this.filters.query = this.query;
    }
    await this.getProducts();
  },
  methods: {
    getBranchIntervalTypeOfProduct(cityId, prices) {
      const branch = prices.find((p) => p.city_id === cityId && p.damping);
      return (branch && branch.interval_type) || null;
    },
    async getProducts() {
      this.loading = true;
      const result = await getProducts(this.filters);
      const { products, pages_count, total } = result;
      this.products = products;
      this.filters.pages_count = pages_count;
      this.filters.total = total;
      this.loading = false;
    },
    handleSelect(val) {
      this.$router.push({ name: "Product", params: { id: val.id } });
    },
    editProduct(product) {
      this.$router.push({ name: "Product", params: { id: product.id } });
      // const routeData = this.$router.resolve({
      //   name: "Product",
      //   params: { id: product.id },
      // });
      // window.open(routeData.href, "_blank");
    },
    async confirmDelete(productId) {
      this.$confirm(
        "Вы действительно хотите удалить. Продолжить?",
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      ).then(async () => {
        await this.deleteProduct(productId);
      });
    },
    async deleteProduct(productId) {
      try {
        this.loading = true;
        const result = await deleteProduct(productId);
        this.loading = false;
        if (result.success) {
          this.$message.success("Успешно");
          await this.getProducts();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
        this.loading = false;
      }
    },
    async handleAvatarSuccess(res) {
      try {
        this.loading = true;
        const result = await importProducts(res.url);
        if (result.success) {
          this.$message.success("Успешно");
          await this.getProducts();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    async loadProducts(res) {
      try {
        this.loading = true;
        const result = await importFormpProducts(res.url);
        if (result.success) {
          this.$message.success("Успешно");
          await this.getProducts();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    async importHalykProducts(res) {
      try {
        this.loading = true;
        const result = await importHalykProducts(res.url);
        if (result.success) {
          this.$message.success("Успешно");
          await this.getProducts();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    async parseKaspiLog() {
      try {
        this.loading = true;
        const result = await parseKaspiLog(this.logs);
        if (result.success) {
          this.$message.success("Успешно");
          this.dialogVisible = false;
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime * 1000);
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          type: this.filters.type,
          page: this.filters.page,
          damping: this.filters.damping,
          interval_type: this.filters.interval_type,
          pre_order: this.filters.pre_order,
          query: this.filters.query,
        },
      });
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getProducts();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
    selectProduct(productId) {
      const index = this.selected_products_id.indexOf(productId);
      if (index >= 0) {
        this.selected_products_id.splice(index, 1);
      } else {
        this.selected_products_id.push(productId);
      }
    },
    exportProducts(market) {
      window.open(
        `${DOMAIN}/xml/export/${market}/${this.user.fileName}`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.low {
  @apply bg-green-500 text-white;
}
.mid {
  @apply bg-yellow-500 text-white;
}
.high {
  @apply bg-red-500 text-white;
}
</style>
