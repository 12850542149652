<template>
  <el-card class="fixed bottom-4 right-8 z-10 w-auto" shadow="always">
    Выбрано: {{ selected_products_id.length }}
    <div class="mt-4">
      <el-button
        :disabled="isProcessing"
        @click="removeFromSale"
        size="medium"
        type="warning"
        plain
        :icon="loading.sale ? 'el-icon-loading' : ''"
        >Снять с продажи</el-button
      >
      <el-button
        v-if="showDamping"
        :disabled="isProcessing"
        @click="removeFromDamping"
        size="medium"
        type="danger"
        plain
        :icon="loading.damping ? 'el-icon-loading' : ''"
        >Снять с демпинга
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { removeFromSale, removeFromDamping } from "@/api/product";

export default {
  name: "ProductsGroupEvents",
  props: {
    selected_products_id: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: {
      sale: false,
      damping: false,
    },
  }),
  computed: {
    isProcessing() {
      for (let x in this.loading) {
        if (this.loading[x]) {
          return true;
        }
      }
      return false;
    },
    showDamping() {
      return location.hostname !== "app.formp.kz";
    },
  },
  methods: {
    async removeFromSale() {
      this.loading.sale = true;
      const result = await removeFromSale(this.selected_products_id);
      this.loading.sale = false;
      if (result.success) {
        this.$notify.success({
          title: "Успех",
          message: "Успешно сняты с продажи",
        });
        return;
      }
      this.$notify.error({ title: "Ошибка", message: "Произошла ошибка" });
      console.log(result);
    },
    async removeFromDamping() {
      this.loading.damping = true;
      const result = await removeFromDamping(this.selected_products_id);
      this.loading.damping = false;
      if (result.success) {
        this.$notify.success({
          title: "Успех",
          message: "Успешно сняты с демпинга",
        });
        return;
      }
      this.$notify.error({ title: "Ошибка", message: "Произошла ошибка" });
      console.log(result);
    },
  },
};
</script>

<style scoped></style>
